import React from 'react'
import IndexLayout from '../layouts'
import TermsSection from '../components/TermsSection'
import TermsTable from '../components/TermsTable'
import { appendUrlPartnerTracking } from '../util/functions'

const TermsAndConditions = () => {
  return (
    <IndexLayout
      title="Terms & Conditions - Bob's Business"
      heading="Terms & Conditions"
      body="BB Standard Terms & Conditions of Suppy of Services"
      currentPage={-5}
    >
      <TermsSection heading="1. Definitions">
        <p>When you see “we” or “our” or “us”, this is a reference to Bob’s Business Ltd</p>

        <p>When you see “you” or “your” we are referring to the organisation or person that is registered with us to use the BB services</p>

        <p>
          When we say “Terms” this means Bob’s Business terms and conditions, which also includes our{' '}
          <a target="_blank" href={appendUrlPartnerTracking('/privacy-policy')}>
            privacy policy
          </a>
          .
        </p>

        <p>
          When we say “Websites,” we mean our website located at bobsbusiness.co.uk, including all subdomains and sites associated with
          those Bob’s Business, and any other websites that we operate now and in the future.
        </p>
        <p>When we say “BB or Bob’s Business,” we mean our Websites and Services collectively.</p>
        <p>
          When we say “information,” we mean all of the different forms of data that you provide us and that we collect from you from your
          use of the Services, your software, and your devices.
        </p>
      </TermsSection>
      <TermsSection heading="2. Interpretations">
        <h3>2.1</h3>
        <p>
          <strong> The following expressions shall have the following meanings:</strong>
        </p>
        <p>
          "<strong>Agreement</strong>" means each Subscription Agreement together with these Conditions;
        </p>

        <p>
          "<strong>Applicable Laws</strong>" means all applicable laws, statutes, regulations and codes from time in force;&nbsp;
        </p>

        <p>
          "<strong>Artwork</strong>" means logos, characters and other images owned by BB;
        </p>

        <p>
          "<strong>Back-Up Policy</strong>" means BB's archiving procedures a copy of which can be found on the BB website at
          [client].bobsbusiness.co.uk and as may be amended by BB in its sole discretion from time to time but which shall not be less
          favourable to the Customer than those in place at the Start Date;
        </p>

        <p>
          "<strong>BB</strong>" means Bob's Business Limited<strong> </strong>incorporated and registered in England and Wales with company
          number 06341794 whose registered office is at DMC, County Way, Barnsley, South Yorkshire, S70 2JW;
        </p>

        <p>
          "<strong>BB Training Catalogue</strong>" means the Modules listed as shown at{' '}
          <a target="_blank" href="https://bobsbusinessstore.bobsbusiness.co.uk">
            https://bobsbusinessstore.bobsbusiness.co.uk
          </a>
          &nbsp; at any given time;
        </p>

        <p>
          "<strong>Business Day</strong>" means any day which is not a Saturday, Sunday or public holiday in England and Wales;&nbsp;
        </p>

        <p>
          "<strong>Confidential Information</strong>" means information that is proprietary or confidential and is either clearly labelled
          as such or identified as Confidential Information in clause 2;
        </p>

        <p>
          "<strong>Customer</strong>" means the organisation named in a Subscription Agreement;
        </p>

        <p>
          "<strong>Customer Data</strong>" means the data inputted by you, your users, or BB on the Customer's behalf, for the purpose of
          supplying or using the Services or facilitating the Customer's use of the Services and may include Personal Data;
        </p>

        <p>
          "<strong>Customer Equipment</strong>" means a computer with an up to date browser and the latest Flash or Java software installed
          or as otherwise specified in writing by BB;
        </p>

        <p>
          "<strong>Customer Materials</strong>" means any materials which the Customer provides to BB for the provision of Services;
        </p>

        <p>
          "<strong>Data Controller</strong>" has the meaning set out in the GDPR;
        </p>

        <p>
          "<strong>Data Processor</strong>" has the meaning set out in the GDPR;
        </p>

        <p>
          "<strong>Data Protection Laws</strong>" means all applicable laws and regulations relating to the processing of personal data and
          privacy, including but not limited to: (i) the General Data Protection Regulation (EU2016/679) ("<strong>GDPR</strong>"); (ii) any
          laws which implement, replace, extend, re-enact, consolidate or amend the foregoing;
        </p>

        <p>
          "<strong>Data Subject</strong>" means an individual who is the subject of Personal Data;
        </p>

        <p>
          "<strong>Data Subject Request</strong>" means a request or complaint from (or on behalf of) a Data Subject exercising their rights
          under the Data Protection Laws;
        </p>

        <p>
          "<strong>Deliverables</strong>" means any output of the Services to be provided by BB to the Customer by BB, as agreed in a
          Subscription Agreement;
        </p>

        <p>
          "<strong>Documentation</strong>" means all documents, whether in print or electronically provided to the Customer by BB further to
          the provision of the Services, including the Artwork;
        </p>

        <p>
          "<strong>Intellectual Property Rights</strong>" means patents, rights to inventions, copyright and&nbsp; related rights, moral
          rights, trade marks, business names and domain names, rights in get-up goodwill and the right to sue for passing off, rights in
          designs, rights in computer software, database rights, rights to use, and protect the confidentiality of, confidential information
          (including know-how and trade secrets) and all other intellectual property rights, in each case whether registered or unregistered
          and including all applications and rights to apply for and be granted, renewals or extensions of, and rights to claim priority
          from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist now or in the future
          in any part of the world;
        </p>

        <p>
          "<strong>Modules</strong>" means the modules of the Services as described in the BB Training Catalogue from time to time;
        </p>

        <p>
          "<strong>New Releases</strong>" means upgrades of the Modules that modify or enhance the Modules' functionality or capability;
        </p>

        <p>
          "<strong>Normal Business Hours</strong>" means 9.00 am to 5.00 pm local UK time, each Business Day;
        </p>

        <p>
          "<strong>Ongoing Development</strong>" means the ongoing development and improvement of the Modules;
        </p>

        <p>
          "<strong>Personal Data</strong>" has the meaning set out the GDPR and relates only to personal data, in respect of which BB is
          providing services under this Agreement as particularised in the Schedule;
        </p>

        <p>
          "<strong>Personal Data Breach</strong>" has the meaning set out in the GDPR and, for the avoidance of doubt, includes a breach of
          clause 4;
        </p>

        <p>
          "<strong>Processing and process</strong>" have the meaning set out in the GDPR;
        </p>

        <p>
          "<strong>Privacy and Security Policies</strong>" means BB's privacy and security policies in relation to Customer Data available
          at{' '}
          <a href="http://bobsbusiness.co.uk" target="blank">
            http://bobsbusiness.co.uk
          </a>{' '}
          or such other website address as may be notified to the Customer from time to time;
        </p>

        <p>
          "<strong>Regulator</strong>" means a Supervisory Authority, as defined in the GDPR;
        </p>

        <p>
          "<strong>Regulatory Correspondence</strong>" means any correspondence (whether written or verbal) from or to a Regulator in
          relation to the Processing of the Personal Data;
        </p>

        <p>
          "<strong>Subscription Agreement</strong>" means any agreement between BB and the Customer for the provision of Services;
        </p>

        <p>
          "<strong>Services</strong>" means the services to be provided by BB to the Customer, as described in a Subscription Agreement;
        </p>

        <p>
          "<strong>Software</strong>" means the online software applications provided by BB and its partners as part of the Services
          including any updates and modifications made available from time to time by BB;
        </p>

        <p>
          "<strong>Start Date</strong>" means the date on which BB has agreed to commence the provision of Services under a Subscription
          Agreement;
        </p>

        <p>
          "<strong>Support Services</strong>" means Technical Support and includes Updates and New Releases;
        </p>

        <p>
          "<strong>Technical Support</strong>" means the maintenance and support provided by BB for the subscribed Services set out in this
          subscription agreement;
        </p>

        <p>
          "<strong>Updates</strong>" means corrections to problems within the Modules;
        </p>

        <p>
          "<strong>Users</strong>" means any user of the Services authorised by the Customer;
        </p>

        <p>
          "<strong>Virus</strong>" means any thing or device (including any software, code, file or programme) which may: prevent, impair or
          otherwise adversely affect the operation of any computer software, hardware or network, any telecommunications service, equipment
          or network or any other service or device; prevent, impair or otherwise adversely affect access to or the operation of any
          programme or data, including the reliability of any programme or data (whether by rearranging, altering or erasing the programme
          or data in whole or part or otherwise); or adversely affect the user experience, including worms, trojan horses, viruses and other
          similar things or devices.
        </p>
        <h3>2.2</h3>
        <p>Clause, schedule and paragraph headings shall not affect the interpretation of these Conditions.</p>
        <h3>2.3</h3>
        <p>
          A <strong>person</strong> includes a natural person, corporate or unincorporated body (whether or not having separate legal
          personality).
        </p>
        <h3>2.4</h3>
        <p>Words in the singular shall include the plural and vice versa.</p>
        <h3>2.5</h3>
        <p>
          A reference to a <strong>company</strong> shall include any company, corporation or other body corporate, wherever and however
          incorporated or established.
        </p>
        <h3>2.6</h3>
        <p>
          The Agreement shall be binding on, and ensure to the benefit of, the parties to the Agreement and their respective personal
          representatives, successors and permitted assigns, and references to any party shall include that party's personal
          representatives, successors and permitted assigns.
        </p>
        <h3>2.7</h3>
        <p>Any obligation on a party not to do something includes an obligation not to allow that thing to be done.</p>
        <h3>2.8</h3>
        <p>
          A reference to this agreement or to any other agreement or document referred to in this agreement is a reference to this agreement
          or such other agreement or document as varied or novated (in each case, other than in breach of the provisions of this agreement)
          from time to time.
        </p>
        <h3>2.9</h3>
        <p>
          Any words following the terms including, include, in particular, for example or any similar expression shall be construed as
          illustrative and shall not limit the sense of the words, description, definition, phrase or term preceding those terms.
        </p>
        <h3>2.10</h3>
        <p>
          A reference to a statute or statutory provision is a reference to it as it is in force for the time being, taking account of any
          amendment, extension, or re-enactment and includes any subordinate legislation for the time being in force made under it.
        </p>
        <h3>2.11</h3>
        <p>A reference to writing or written includes e-mail.</p>
      </TermsSection>
      <TermsSection heading="3. BB Obligations">
        <h3>3.1</h3>
        <p>
          BB shall, during the Term, provide the Services and Documentation to the Customer on and subject to the terms of the relevant
          Subscription Agreement and these Conditions. These Conditions shall apply to the provision of all Services unless varied in
          writing by the parties.
        </p>
        <h3>3.2</h3>
        <p>BB shall use commercially reasonable endeavours to make the Services available 24 hours a day, seven days a week, except for:</p>
        <ol>
          <li>
            <span>(a)</span> planned maintenance carried out during the maintenance window of 10.00 pm to 2.00 am UK time; and
          </li>
          <li>
            <span>(b)</span> unscheduled maintenance performed outside Normal Business Hours, provided that BB has used reasonable
            endeavours to give the Customer at least 6 Normal Business Hours' notice in advance.
          </li>
        </ol>
        <h3>3.3</h3>
        <p>
          BB does not warrant that the Customer's use of the Services will be uninterrupted or error-free; or that the Services,
          Documentation and/or the information obtained by the Customer through the Services will meet the Customer's requirements.
        </p>
        <h3>3.4</h3>
        <p>
          BB is not responsible for any delays, delivery failures, or any other loss or damage resulting from the transfer of data over
          communications networks and facilities, including the internet, and the Customer acknowledges that the Services and Documentation
          may be subject to limitations, delays and other problems inherent in the use of such communications facilities.
        </p>
        <h3>3.5</h3>
        <p>
          BB will provide Technical Support to the Customer during Normal Business Hours for problems that are the direct result of an error
          or defect in the Modules as supplied by BB.
        </p>
        <h3>3.6</h3>
        <p>BB will not be responsible for providing support in relation to:</p>
        <ol>
          <li>
            <span>(a)</span> improper use of the Modules;
          </li>
          <li>
            <span>(b)</span> faults caused by using the Modules outside the terms of this agreement or outside the provisions laid down in
            any documentation or manual supplied with the Modules; or
          </li>
          <li>
            <span>(c)</span> software, accessories, attachments, machines, systems or other devices that were not supplied by BB.
          </li>
          <li>
            <span>(d)</span> Support on accounts that have expired or are in arrears
          </li>
        </ol>
        <h3>3.7</h3>
        <p>
          As part of any Ongoing Development, BB may, at its sole discretion, issue Updates or New Releases of the Modules from time to
          time, without charge to the Customer.
        </p>
        <h3>3.8</h3>
        <p>
          In performing its obligations under this Agreement, BB shall comply with the Applicable Laws. Changes to the Services required as
          a result of changes to the Applicable Laws shall be agreed in writing with the Customer.
        </p>
      </TermsSection>
      <TermsSection heading="4. Customer Obligations">
        <h3>4.1</h3>
        <p>The Customer shall:</p>
        <ol>
          <li>
            <span>(a)</span> only use the Services and Documentation in accordance with the terms of the Agreement and for its own internal
            business purposes;
          </li>
          <li>
            <span>(b)</span> ensure that the Customer Equipment is operational at the Start Date and is kept in working order during the
            Term;
          </li>
          <li>
            <span>(c)</span> provide BB with all necessary co-operation in relation to the Agreement and all necessary access to such
            information as may be required by BB in order to provide the Services, including but not limited to Customer Data, Customer
            Equipment, security access information and configuration services;
          </li>
          <li>
            <span>(d)</span> comply with all applicable laws and regulations with respect to its activities under the Agreement;
          </li>
          <li>
            <span>(e)</span> carry out all other Customer responsibilities set out in the Agreement in a timely and efficient manner. In the
            event of any delays in the Customer's provision of such assistance as agreed by the parties, BB may adjust any agreed timetable
            or delivery schedule as reasonably necessary;
          </li>
          <li>
            <span>(f)</span> ensure that the Users use the Services and the Documentation in accordance with the terms and conditions of the
            Agreement and shall be responsible for any User’s breach of the Agreement;
          </li>
          <li>
            <span>(g)</span> not allow any User profile to be used by more than one individual person unless it has been reassigned in its
            entirety to a different individual;
          </li>
          <li>
            <span>(h)</span> ensure that its network and systems comply with any relevant specifications provided by BB from time to time;
          </li>
          <li>
            <span>(i)</span> use commercially reasonable endeavours to prevent any unauthorised access to or use of the Services, and in the
            event of any such unauthorised access or use, shall promptly inform BB;
          </li>
          <li>
            <span>(j)</span> be solely responsible for procuring and maintaining its network connections and telecommunications links from
            its systems to BB's data centres, and all problems, conditions, delays, delivery failures and all other loss or damage arising
            from or relating to the Customer's network connections or telecommunications links or caused by the internet; and
          </li>
          <li>
            <span>(k)</span> defend, indemnify and hold harmless BB against any claims, actions, proceedings, losses, damages, expenses and
            costs (including without limitation court costs and reasonable legal fees) arising out of or in connection with BB's use of the
            Customer Data or Customer's Intellectual Property Rights used by BB in accordance with the Agreement.
          </li>
        </ol>
        <h3>4.2</h3>
        <p>The Customer shall not:</p>
        <ol>
          <li>
            <span>(a)</span> except as may be allowed by any applicable law which is incapable of exclusion by agreement between the
            parties; and
          </li>
          <li>
            <span>(b)</span> except to the extent expressly permitted under these Conditions, copy, modify, duplicate, translate, create
            derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Software
            and/or Documentation (as applicable) or attempt to do the same, in any form or media or by any means;
          </li>
          <li>
            <span>(c)</span> reverse compile, disassemble, reverse engineer or otherwise reduce to human-perceivable form or attempt to do
            the same to, all or any part of the Software; or
          </li>
          <li>
            <span>(d)</span> access all or any part of the Services and Documentation in order to build a product or service which competes
            with the Services; or
          </li>
          <li>
            <span>(e)</span> circumvent or manipulate any of the restrictions or security related features such as with respect to the
            number of permitted end-users; or
          </li>
          <li>
            <span>(f)</span> use the Services and/or Documentation to provide services to third parties; or
          </li>
          <li>
            <span>(g)</span> subject to clause 15, license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise
            commercially exploit, or otherwise make available all or part of the Services or the Documentation to any third party except the
            Users; or
          </li>
          <li>
            <span>(h)</span> attempt to obtain access or copy, or assist third parties in obtaining access or copying, the Services, the
            Artwork and/or Documentation, other than as provided under these Conditions;
          </li>
          <li>
            <span>(i)</span> do anything which may negatively impact on the platform IT system or environment or availability of the
            Services;
          </li>
          <li>
            <span>(j)</span> access, store, distribute, post, upload or transmit any Viruses, or any material during the course of its use
            of the Services that:
          </li>
          <section>
            <ol>
              <ul>
                <li>
                  <span>(i)</span> is unlawful, harmful, threatening, defamatory, obscene, infringing, harassing or racially or ethnically
                  offensive;
                </li>

                <li>
                  <span>(ii)</span> facilitates illegal activity;
                </li>
                <li>
                  <span>(iii)</span> depicts sexually explicit images;
                </li>
                <li>
                  <span>(iv)</span> promotes unlawful violence;
                </li>
                <li>
                  <span>(v)</span> is discriminatory based on race, gender, colour, religious belief, sexual orientation, disability; or
                </li>
                <li>
                  <span>(vi)</span> is in a manner that is otherwise illegal or causes damage or injury to any person or property; and
                </li>
              </ul>
            </ol>
          </section>
          <li>
            <span>(k)</span> BB reserves the right, without liability or prejudice to its other rights to the Customer, to disable the
            Customer's access to any material that breaches the provisions of this clause.
          </li>
        </ol>
        <h3>4.3</h3>
        <p>
          The Customer shall use all reasonable endeavours to prevent any unauthorised access to, or use of, the Services and/or the
          Documentation and, in the event of any such unauthorised access or use, promptly notify BB.
        </p>
      </TermsSection>
      <TermsSection heading="5. Customer Data and Personal Data">
        <h3>5.1</h3>
        <p>
          The Customer shall own all right, title and interest in and to all of the Customer Data and shall have sole responsibility for the
          legality, reliability, integrity, accuracy and quality of the Customer Data.
        </p>
        <h3>5.2</h3>
        <p>
          Each party warrants that in respect of the Personal Data that it is processing further to the Subscription Agreement, it shall
          comply with its obligations under the Data Protection Laws.
        </p>
        <h3>5.3</h3>
        <p>
          BB shall follow its Back-Up Policy for Customer Data. BB shall not be responsible for any loss, destruction, alteration or
          disclosure of Customer Data caused by any third party (except those third parties sub-contracted by BB to perform services related
          to Customer Data maintenance and back-up).
        </p>
        <h3>5.4</h3>
        <p>
          The Customer acknowledges that all of the Personal Data to be Processed by BB further to the Agreement is being provided by the
          Customer and therefore the Customer warrants that:
        </p>
        <ol>
          <li>
            <span>(a)</span> it has a lawful basis to transfer the Personal Data to BB; and
          </li>
          <li>
            <span>(b)</span> any Processing by BB, in accordance with the terms of this Agreement, shall not be a breach of the Data
            Protection Laws.
          </li>
        </ol>
        <h3>5.5</h3>
        <p>
          BB shall, in providing the Services, comply with its Privacy and Security Policies relating to the privacy and security of the
          Customer Data. BB shall process the Personal Data only in accordance with the Customer's instructions from time to time and shall
          not process the Personal Data for any purposes other than those authorised by the Customer in writing and as required for the
          supply of the Services. If BB believes that any such instructions are in breach of the Data Protection Laws it shall promptly
          notify the Customer and the parties will agree on the appropriate course of action.
        </p>
        <h3>5.6</h3>
        <p>
          BB shall use all reasonable endeavours to provide such assistance and co-operation as is necessary or reasonably requested to
          assist the Customer in complying with the Data Protection Laws. In particular, BB shall allow the Customer, its employees,
          authorised agents or advisers, upon reasonable prior notice, sufficient access to any of BB's records and documents and any other
          information necessary to demonstrate compliance with the terms of these Conditions and/or on the request of the Customer, provide
          the Customer with written evidence of its compliance.
        </p>
        <h3>5.7</h3>
        <p>
          When BB processes any Personal Data on the Customer's behalf when performing its obligations under the Agreement, the parties
          record their intention that the Customer shall be the Data Controller and BB shall be a Data Processor and:
        </p>
        <ol>
          <li>
            <span>(a)</span> the Customer agrees that should BB need to transfer Personal Data outside the EEA, it may do so provided that
            appropriate legal safeguards are in place as required under the Data Protection Laws;
          </li>
          <li>
            <span>(b)</span> the Customer shall ensure that the Customer is entitled to transfer any relevant Personal Data to BB so that BB
            may lawfully use, process and transfer Personal Data in accordance with the Agreement on the Customer's behalf;
          </li>
          <li>
            <span>(c)</span> the Customer shall ensure that the relevant third parties have been informed of, and have given their consent
            to, such use, processing, and transfer as required by all applicable data protection legislation; and
          </li>
        </ol>
        <ol>
          <li>
            <span>(d)</span> BB shall process Personal Data only in accordance with the terms of the Agreement and any agreement with the
            Customer and any lawful instructions reasonably given by the Customer from time to time.
          </li>
        </ol>

        <h3>5.8</h3>
        <p>
          BB shall implement and maintain such technical and organisational measures as are required to enable the Personal Data to be
          Processed in compliance with the obligations imposed on it by the Data Protection Laws. In particular BB shall:
        </p>
        <ol>
          <li>
            <span>a)</span> take all reasonable steps to ensure the reliability and integrity of any of its personnel who shall have access
            to the Personal Data;
          </li>
          <li>
            <span>b)</span>ensure that only the necessary members of its personnel required, in order to ensure compliance with its
            obligations under the Agreement shall have access to the Personal Data (and no other member of its personnel shall have access
            to such Personal Data); and
          </li>
          <li>
            <span>c)</span>ensure that each member of its personnel shall have:
          </li>
          <ul>
            <li>
              <span>i)</span>undergone appropriate levels of training regarding the Data Protection Laws and in the care and handling of
              Personal Data; and
            </li>
            <li>
              <span>ii)</span>entered into appropriate contractually-binding confidentiality undertakings that shall apply to the Personal
              Data;
            </li>
          </ul>
          <li>
            <span>d)</span>keep all Personal Data confidential and secure by the use of secure passwords, user access controls, encryption,
            technical security such as firewalls and physical security including alarms, secure locks and specific entry passes for
            authorised individuals. However, while BB will use all reasonable efforts to safeguard the Personal Data, the Customer
            acknowledges that the use of the internet is not entirely secure and for this reason BB cannot guarantee the security or
            integrity of any Personal Data that is transferred between the parties via the internet.
          </li>
        </ol>

        <h3>5.9</h3>
        <p>BB shall provide reasonable assistance to the Customer as is necessary to:</p>
        <ol>
          <li>
            <span>(a)</span> enable the Customer to comply with requests by Data Subjects and/or any Regulators to exercise its rights or
            comply with its obligations pursuant to Data Protection Laws; and
          </li>
          <li>
            <span>(b)</span> facilitate the handling of any data security breach in an expeditious and compliant manner.
          </li>
        </ol>
        <h3>5.10</h3>
        <p>
          BB shall notify the Customer promptly (and in any event within 3 working days) following its receipt of any Data Subject Request
          or Regulator Correspondence and shall not disclose any Personal Data in response to any Data Subject Request or Regulator
          Correspondence without the Customer's prior written consent.
        </p>
        <h3>5.11</h3>
        <p>
          BB shall promptly (and in any event within twenty-four (24) hours) notify the Customer upon becoming aware of any Personal Data
          Breach including providing all necessary information as stipulated by the Data Protection Laws and will:
        </p>
        <ol>
          <li>
            <span>(a)</span> take all reasonable steps to prevent or minimise the effects of the Personal Data Breach;
          </li>
          <li>
            <span>(b)</span> implement any measures necessary to restore the security of compromised Personal Data; and
          </li>
          <li>
            <span>(c)</span> provide the Customer with all reasonable cooperation and assistance to make any notifications to any Regulator
            and affected Data Subjects.
          </li>
        </ol>
        <h3>5.12</h3>
        <p>
          Except to the extent required by Data Protection Laws or other applicable laws, on termination of the Agreement, howsoever
          arising, BB shall (and shall procure the same of its subcontractors) cease Processing all Personal Data, will not retain any copy,
          abstract, précis or summary of any Personal Data, and will return and/or securely and permanently destroy (as directed in writing
          by Customer) all Personal Data (including records and documentation and all copies in its possession and control or that of its
          subcontractors) to the extent that such Personal Data is not recoverable by BB with the exception of Customer contact information
          which shall be held in accordance with BB's privacy policy.
        </p>
        <h3>5.13</h3>
        <p>
          Each party agrees to indemnify and keep indemnified and defend at its own expense the other party against all direct costs,
          claims, damages or expenses incurred by the other party or for which the other party may become liable due to any failure by the
          first party or its employees or agents to comply with any of its obligations under this clause 4.
        </p>
        <h3>5.14</h3>
        <p>
          The Customer acknowledges that BB is reliant on the Customer for direction as to the extent to which BB is entitled to use and
          process the Personal Data. Consequently, BB will not be liable for any claim brought by a Data Subject arising from any action or
          omission by BB, to the extent that such action or omission resulted directly from the Customer's instructions or omission.
        </p>
        <h3>5.15</h3>
        <p>
          BB may authorise a third party (<strong>subcontractor</strong>) to process the Personal Data provided that it has a written
          agreement with the subcontractors which:
        </p>
        <ol>
          <li>
            <span>(a)</span> includes terms which are substantially the same as those set out in this clause 4; and
          </li>
          <li>
            <span>(b)</span> terminates automatically on termination of any Subscription Agreement for any reason.
          </li>
        </ol>
      </TermsSection>
      <TermsSection heading="6. Intellectual Property Rights">
        <h3>6.1</h3>
        <p>
          The Customer acknowledges and agrees that BB owns all intellectual property rights in and/or arising from the Services, the
          Artwork and the Documentation. Except as expressly stated herein or in a Subscription Agreement, the Agreement does not grant the
          Customer any rights to, or in, patents, copyrights, database right, trade secrets, trade names, trade marks (whether registered or
          unregistered), or any other rights or licences in respect of the Services, the Artwork or the Documentation.
        </p>
        <h3>6.2</h3>
        <p>In relation to the Deliverables:</p>
        <ol>
          <li>
            <span>(a)</span> BB shall retain ownership of all Intellectual Property Rights in the Deliverables excluding any Customer
            Materials;
          </li>
          <li>
            <span>(b)</span> BB grants the Customer and its Affiliates, a fully paid-up, worldwide, non-exclusive, royalty-free licence
            during the Term to copy the Deliverables for the purpose of receiving and using the Services and the Deliverables in its
            business and the business of its Affiliates; and
          </li>
          <li>
            <span>(c)</span> the Customer shall not sub-license, assign or otherwise transfer the rights granted in clause 5.2(b).
          </li>
        </ol>
        <h3>6.3</h3>
        <p>In relation to any Customer Materials, the Customer:</p>
        <ol>
          <li>
            <span>(a)</span> and its licensors shall retain ownership of all Intellectual Property Rights in the Customer Materials; and
          </li>
          <li>
            <span>(b)</span> grants BB a fully paid-up, non-exclusive, royalty-free, non-transferable licence to copy and modify the
            Customer Materials for the Term for the purpose of providing the Services to the Customer and to use the modified Customer
            Materials for any purpose insofar as such modified Customer Materials do not reference the Customer.
          </li>
        </ol>
        <h3>6.4</h3>
        <p>The Customer:</p>
        <ol>
          <li>
            <span>(a)</span> warrants that the receipt and use of the Customer Materials in the performance of the Agreement by BB, its
            agents, subcontractors or consultants shall not infringe the rights, including any Intellectual Property Rights, of any third
            party; and
          </li>
          <li>
            <span>(b)</span> shall keep BB indemnified in full against all costs, expenses, damages and losses, including any interest,
            fines, legal and other professional fees and expenses awarded against or incurred or paid by BB as a result of or in connection
            with any claim brought against BB, its agents, subcontractors or consultants for actual or alleged infringement of a third
            party's Intellectual Property Rights arising out of, or in connection with, the receipt or use in the performance of the
            Agreement of the Customer Materials.
          </li>
        </ol>
      </TermsSection>
      <TermsSection heading="7. Confidentiality">
        <h3>7.1</h3>
        <p>
          Each party undertakes that it shall not at any time during the term of any Subscription Agreement, and for a period of two years
          after termination of any Subscription Agreement, disclose to any person any confidential information concerning the business,
          affairs, customers, clients or BBs of the other party or of any member of the group of companies to which the other party belongs,
          except as permitted by clause 6.2.
        </p>
        <h3>7.2</h3>
        <p>Each party may disclose the other party's confidential information:</p>
        <ol>
          <li>
            <span>(a)</span> to its employees, officers, representatives or advisers who need to know such information for the purposes of
            exercising the party's rights or carrying out its obligations under or in connection with this Agreement. Each party shall
            ensure that its employees, officers, representatives or advisers to whom it discloses the other party's confidential information
            comply with this clause 6; and
          </li>
          <li>
            <span>(b)</span> as may be required by law, a court of competent jurisdiction or any governmental or regulatory authority.
          </li>
        </ol>
        <h3>7.3</h3>
        <p>
          No party shall use any other party's confidential information for any purpose other than to exercise its rights and perform its
          obligations under or in connection with the Agreement.
        </p>
      </TermsSection>
      <TermsSection heading="8. Termination">
        <h3>8.1</h3>
        <p>
          Without affecting any other right or remedy available to it either party may terminate the Agreement with immediate effect by
          giving written notice to the other party, if:
        </p>
        <ol>
          <li>
            <span>(a)</span> the other party commits a material breach of any term of the Agreement which breach is irremediable or (if such
            breach is remediable) fails to remedy that breach within a period of 14 days after being notified in writing to do so;
          </li>
          <li>
            <span>(b)</span> the other party suspends, or threatens to suspend, payment of its debts or is unable to pay its debts as they
            fall due or admits inability to pay its debts or (being a company or limited liability partnership) is deemed unable to pay its
            debts within the meaning of section 123 of the Insolvency Act 1986;
          </li>
          <li>
            <span>(c)</span> the other party suspends or ceases, or threatens to suspend or cease, carrying on all or a substantial part of
            its business.
          </li>
        </ol>
        <h3>8.2</h3>
        <p>
          Without affecting any other right or remedy available to it, BB may terminate the Agreement with immediate effect by giving
          written notice to the Customer if the Customer fails to pay any amount due under the Agreement on the due date for payment and
          remains in default not less than 14 days after being notified in writing to make such payment.
        </p>
      </TermsSection>
      <TermsSection heading="9. Consequences of termination">
        <h3>9.1</h3>
        <p>On termination or expiry of this Agreement:</p>
        <ol>
          <li>
            <span>(a)</span> BB shall on request return or destroy any of the Customer Materials provided further to this Agreement; and
          </li>
          <li>
            <span>(b)</span> the following clauses shall continue in force: clause 1 (Interpretation), clause 5 (Intellectual property
            rights), clause 6 (Confidentiality), clause 8 (Consequences of termination), clause 9 (Limitation of liability), clause 11
            (Waiver), clause 13 (Severance), clause 14 (Conflict), clause 19 (Governing law and Jurisdiction).
          </li>
        </ol>
        <h3>9.2</h3>
        <p>
          Termination or expiry of this Agreement shall not affect any rights, remedies, obligations or liabilities of the parties that have
          accrued up to the date of termination or expiry, including the right to claim damages in respect of any breach of the agreement
          which existed at or before the date of termination or expiry.
        </p>
        <h3>9.3</h3>
        <p>On termination of this Agreement for any reason:</p>
        <ol>
          <li>
            <span>(a)</span> all licences granted under this Agreement shall immediately terminate;
          </li>
          <li>
            <span>(b)</span> the Customer shall return and make no further use of any equipment, property, Artwork, Documentation and other
            items (and all copies of them) belonging to BB;
          </li>
          <li>
            <span>(c)</span> BB shall with the exception of the key contract personnel data destroy or otherwise dispose of any of the
            Customer Data and personal data in its possession unless BB receives, no later than ten days after the termination of the
            Agreement, a written request for the delivery to the Customer of the then most recent back-up of the Customer Data. BB shall use
            reasonable commercial endeavours to deliver the back-up to the Customer within 30 days of its receipt of such a written request,
            provided that the Customer has, at that time, paid all fees and charges outstanding at and resulting from termination (whether
            or not due at the date of termination). The Customer shall pay all reasonable expenses incurred by BB in returning or disposing
            of Customer Data;
          </li>
          <li>
            <span>(d)</span> the accrued rights of the parties as at termination, or the continuation after termination of any provision
            expressly stated to survive or implicitly surviving termination, shall not be affected or prejudiced.
          </li>
        </ol>
      </TermsSection>
      <TermsSection heading="10. Limitation of liability">
        <h3>10.1</h3>
        <p>Nothing in these terms and conditions or subscription Agreement shall limit or exclude either party's liability for:</p>
        <ol>
          <li>
            <span>(a)</span> death or personal injury caused by its negligence;
          </li>
          <li>
            <span>(b)</span> fraud or fraudulent misrepresentation; or
          </li>
          <li>
            <span>(c)</span> any other liability which cannot be limited or excluded by applicable law.
          </li>
        </ol>
        <h3>10.2</h3>
        <p>
          Subject to clause 9.1, neither party shall be liable to the other, whether in contract, tort (including negligence), for breach of
          statutory duty, or otherwise, arising under or in connection with the Agreement:
        </p>
        <ol>
          <li>
            <span>(a)</span> loss of profits;
          </li>
          <li>
            <span>(b)</span> loss of sales or business;
          </li>
          <li>
            <span>(c)</span> loss of agreements or contracts;
          </li>
          <li>
            <span>(d)</span> loss of anticipated savings;
          </li>
          <li>
            <span>(e)</span> loss of or damage to goodwill;
          </li>
          <li>
            <span>(f)</span> loss of use or corruption of software, data or information; and
          </li>
          <li>
            <span>(g)</span> any indirect or consequential loss.
          </li>
        </ol>
        <h3>10.3</h3>
        <p>Except as expressly and specifically provided in the Agreement:</p>
        <ol>
          <li>
            <span>(a)</span> the Customer assumes sole responsibility for results obtained from the use of the Services and the
            Documentation by the Customer, and for conclusions drawn from such use. BB shall have no liability for any damage caused by
            errors or omissions in any information, instructions or scripts provided to BB by the Customer in connection with the Services,
            or any actions taken by BB at the Customer's direction;
          </li>
          <li>
            <span>(b)</span> all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or
            common law are, to the fullest extent permitted by applicable law, excluded from the Agreement; and
          </li>
          <li>
            <span>(c)</span> the Services and the Documentation are provided to the Customer on an "as is" basis.
          </li>
        </ol>
        <h3>10.4</h3>
        <p>
          Subject to clause 9.1, each party's total liability to the other, whether in contract, tort (including negligence), for breach of
          statutory duty, or otherwise, arising under or in connection with the Agreement shall be limited to the sum of £250,000.
        </p>
        <h3>10.5</h3>
        <p>
          The terms implied by sections 3 and 5 of the Supply of Goods and Services Act 1982 are, to the fullest extent permitted by law,
          excluded from the Agreement.
        </p>
      </TermsSection>
      <TermsSection heading="11. Force Majeure">
        <h3>11.1</h3>
        <p>
          BB shall have no liability to the Customer under the Agreement if it is prevented from or delayed in performing its obligations
          under the Agreement, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control,
          including, without limitation, strikes, lock-outs or other industrial disputes (whether involving the workforce of BB or any other
          party), failure of a utility service or transport or telecommunications network, act of God, war, riot, civil commotion, malicious
          damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery,
          fire, flood, storm or default of BBs or sub-contractors, provided that the Customer is notified of such an event and its expected
          duration.
        </p>
      </TermsSection>
      <TermsSection heading="12. Waiver">
        <h3>12.1</h3>
        <p>
          A waiver of any right under the Agreement is only effective if it is in writing and it applies only to the party to whom the
          waiver is addressed and to the circumstances for which it is given.
        </p>
        <h3>12.2</h3>
        <p>
          Unless specifically provided otherwise, rights arising under the Agreement are cumulative and do not exclude rights provided by
          law.
        </p>
      </TermsSection>
      <TermsSection heading="13. Rights and remedies">
        <h3>13.1</h3>
        <p>
          The rights and remedies provided under the Agreement are in addition to, and not exclusive of, any rights or remedies provided by
          law.
        </p>
      </TermsSection>
      <TermsSection heading="14. Severance">
        <h3>14.1</h3>
        <p>
          If any provision (or part of a provision) of the Agreement is found by any court or administrative body of competent jurisdiction
          to be invalid, unenforceable or illegal, the other provisions shall remain in force.
        </p>
        <h3>14.2</h3>
        <p>
          If any invalid, unenforceable or illegal provision would be valid, enforceable or legal if some part of it were deleted, the
          provision shall apply with whatever modification is necessary to give effect to the commercial intention of the parties.
        </p>
      </TermsSection>
      <TermsSection heading="15. Conflict">
        <h3>15.1</h3>
        <p>
          In the event of any conflict between the terms of the Conditions and a Subscription Agreement the terms of the Subscription
          Agreement shall prevail.
        </p>
      </TermsSection>
      <TermsSection heading="16. Assignment and other dealings">
        <h3>16.1</h3>
        <p>
          Either party may assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations
          under the Agreement subject to giving prior written notice to the other party.
        </p>
      </TermsSection>
      <TermsSection heading="17. Entire Agreement">
        <h3>17.1</h3>
        <p>
          These Conditions together with any Subscription Agreement constitute the entire agreement between the parties and supersedes and
          extinguishes all previous agreements, promises, assurances, warranties, representations and understandings between them, whether
          written or oral, relating to its subject matter.
        </p>
        <h3>17.2</h3>
        <p>
          Each party agrees that it shall have no remedies in respect of any statement, representation, assurance or warranty (whether made
          innocently or negligently) that is not set out in the Agreement. Each party agrees that it shall have no claim for innocent or
          negligent misrepresentation or negligent misstatement based on any statement in the Agreement.
        </p>
      </TermsSection>
      <TermsSection heading="18. No partnership or agency">
        <h3>18.1</h3>
        <p>
          Nothing in the Agreement is intended to or shall operate to create a partnership between the parties, or authorise either party to
          act as agent for the other, and neither party shall have the authority to act in the name or on behalf of or otherwise to bind the
          other in any way (including, but not limited to, the making of any representation or warranty, the assumption of any obligation or
          liability and the exercise of any right or power).
        </p>
      </TermsSection>
      <TermsSection heading="19. Third Party Rights">
        <h3>19.1</h3>
        <p>
          The Agreement does not confer any rights on any person or party (other than the parties to the Agreement and, where applicable,
          their successors and permitted assigns) pursuant to the Contracts (Rights of Third Parties) Act 1999.
        </p>
        <h3>19.2</h3>
        <p>
          No one other than a party to the Agreement, their successors and permitted assignees, shall have any right to enforce any of its
          terms.
        </p>
      </TermsSection>
      <TermsSection heading="20. Governing law and jurisdiction">
        <h3>20.1</h3>
        <p>
          The Agreement and any disputes or claims arising out of or in connection with it or its subject matter or formation (including
          non-contractual disputes or claims) shall be governed by and construed in accordance with English law.
        </p>
        <h3>20.2</h3>
        <p>
          The parties irrevocably agree that the courts of England and Wales shall have exclusive jurisdiction to settle any dispute or
          claim that arises out of or in connection with the Agreement or its subject matter or formation (including non-contractual
          disputes or claims).
        </p>
      </TermsSection>
      <TermsSection heading="Schedule">
        <TermsTable />
      </TermsSection>
    </IndexLayout>
  )
}

export default TermsAndConditions
